<template>
  <div class="main">
    <the-top-bar></the-top-bar>

    <section class="main-info">
      <b-row class="h-100">
        <b-col cols="12" md="6">
          <div class="hero-text d-flex flex-column text-center justify-content-center h-100">
            <h1 class="sub-title text-white mt-3 mb-5 mb-md-0">We help companies work efficiently and faster with digital forms.</h1>
          </div>
        </b-col>

        <b-col cols="12" md="6" class="position-relative">
          <img src="~@/assets/hero-bg-cropped.jpg" alt="Gas Certificate App Mockup" class="main-info__mockup">
        </b-col>
      </b-row>
    </section>

    <section class="section bg-black" id="apps">
      <b-container data-aos="fade-up">
        <h2 class="text-white display-4 text-center">Our Apps</h2>
        <p class="sub-title text-white text-center">Built for Electrical, Heating and Plumbing, Gas and Oil, and User Management.</p>
        <p class="text-muted text-center"> All of our apps are built with the latest technology, privacy and cross-platform compatibility.</p>
      </b-container>
    </section>

    <section class="section info-section bg-blue-gradient">
      <b-container>
        <img class="section-logo" src="~@/assets/gca_logo.png" alt="Gas Certificate App Logo">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex flex-column justify-content-center h-100">
              <p class="text-yellow header-text">Save over 12 hours per week.</p>
              <h2 class="text-white display-4 h2 underline">Gas Certificate App</h2>
              <p class="text-white">Join over 10,000 engineers saving hours per week with Gas Certificate App. Gas Certificate App is a
                revolutionary
                new product that saves you hours per week by providing you with an all-in-one product from creating Gas Certificates, to
                managing your invoices and estimates.</p>
              <b-btn target="_blank" href="https://gascertificateapp.co.uk" title="Learn more" variant="yellow">Learn more</b-btn>

              <b-row>
                <b-col cols="12" md="4">
                  <div class="stats">
                    <span>10,000</span>
                    <h3 class="h2 stat-title">Users</h3>
                  </div>
                </b-col>

                <b-col cols="12" md="4">
                  <div class="stats">
                    <span>£1m+</span>
                    <h3 class="h2 stat-title">Worth of Invoices</h3>
                  </div>
                </b-col>
                <b-col cols="12" md="4">
                  <div class="stats">
                    <span><ion-icon name="heart"></ion-icon></span>
                    <h3 class="h2 stat-title">By Councils</h3>
                  </div>
                </b-col>
              </b-row>
            </div>

          </b-col>
          <b-col cols="12" md="6" class="text-center">
            <img class="about-more-info__image" src="~@/assets/gca_mockup.png" alt="About Info" data-aos="about-more-info__animation"
                 data-aos-anchor-placement="top-center"/>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section info-section bg-yellow-gradient">
      <b-container>
        <img class="section-logo" src="~@/assets/eca_logo.png" alt="Electrical Certificate App Logo">
        <b-row>
          <b-col cols="12" md="6" class="mb-5" style="position:relative;height:1080px">
            <div class="stacked-images">
              <img src="~@/assets/stack_01.png" alt="Stack 1"
                   style="width: 100%; max-width: 400px;position:absolute;top:23px;left:132px;z-index:4; transform: translateY(160px)"
                   data-aos="stack_01"/>
              <img src="~@/assets/stack_02.png" alt="Stack 2"
                   style="width: 100%; max-width: 600px; position:absolute;top:180px;z-index:3;transform:translateY(120px)"
                   data-aos="stack_02"/>
              <img width="320px" src="~@/assets/stack_03.png" alt="Stack 3"
                   style="width: 320px; max-width: 320px; position:absolute;top:548px;z-index:2;transform:translateY(80px)"
                   data-aos="stack_03"/>
              <img src="~@/assets/stack_04.png" alt="Stack 4"
                   style="width: 100%; max-width: 400px; position:absolute;bottom:0px;;transform:translateY(80px)"
                   data-aos="stack_04"/>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex flex-column justify-content-center h-100">
              <p class="text-blue header-text">Desktop / Android / Apple</p>
              <span class="d-flex"><b-badge variant="primary">NEW</b-badge></span>
              <p class="text-blue display-4 h2 text-black">Electrical Certificate App  </p>
              <p class="text-black">Electrical Certificate App is the latest addition to the Trade Apps family by providing a seamless
                experience to manage all of
                your Electrical Certificates to managing your invoices and estimates, freeing up your weekends.</p>
              <b-btn target="_blank" href="https://electricalcertificateapp.co.uk" title="Learn more" variant="primary">Learn more</b-btn>

<!--              <b-row>-->
<!--                <b-col cols="12" md="4">-->
<!--                  <div class="stats">-->
<!--                    <span>6,000</span>-->
<!--                    <h3 class="h2 stat-title">Users</h3>-->
<!--                  </div>-->
<!--                </b-col>-->

<!--                <b-col cols="12" md="4">-->
<!--                  <div class="stats">-->
<!--                    <span>17</span>-->
<!--                    <h3 class="h2 stat-title">Certificates</h3>-->
<!--                  </div>-->
<!--                </b-col>-->
<!--                <b-col cols="12" md="4">-->
<!--                  <div class="stats">-->
<!--                    <span>£1m+ </span>-->
<!--                    <h3 class="h2 stat-title">Worth of invoices sent</h3>-->
<!--                  </div>-->
<!--                </b-col>-->
<!--              </b-row>-->
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section our-apps">
      <b-container>
        <h1 class="display-4 text-center text-white mb-4">Check our Apps</h1>
        <b-row>
          <b-col class="mb-4" cols="12" lg="6">
            <div class="app-icon text-center d-flex flex-column" data-aos="fade-up">
              <a title="Electrical Certificate App" href="https://www.electricalcertificateapp.co.uk">
                <img alt="Electrical Certificate App Logo" src="./../../assets/app_logos/electricalapp-logo.png"/>
              </a>
              <p class="pt-2 text-white">View more information about Electrical Certificate App</p>
              <a class="btn btn-primary" href="https://electricalcertificateapp.co.uk" target="_blank" title="Electrical Certificate App">Visit
                Website</a>
            </div>

          </b-col>
          <b-col cols="12" lg="6">
            <div class="app-icon text-center d-flex flex-column" data-aos="fade-up" data-aos-offset="500">
              <a title="Gas Certificate App" href="https://www.gascertificateapp.co.uk">
                <img alt="Electrical Certificate App" src="./../../assets/app_logos/gasapp-logo.png"/>
              </a>
              <p class="pt-2 text-white">View more information about Gas Certificate App</p>
              <a class="btn btn-primary" href="https://gascertificateapp.co.uk" target="_blank" title="Gas Certificate App">Visit
                Website</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!--    <section class="section bg-dark-blue">-->
    <!--      <b-container>-->
    <!--        <h2 class="h1 text-white">Contact</h2>-->

    <!--        <h3 class="sub-title text-white">Want to get in contact? Drop us a message below</h3>-->

    <!--        <b-form v-on:submit.prevent="contactForm">-->
    <!--          <b-form-group label="Name">-->
    <!--            <b-form-input type="text" v-model="contactForm.name"></b-form-input>-->
    <!--          </b-form-group>-->

    <!--          <b-form-group label="Email address" description="This is so that we can get in contact with you.">-->
    <!--            <b-form-input type="email" v-model="contactForm.email"></b-form-input>-->
    <!--          </b-form-group>-->

    <!--          <b-form-group label="Phone" description="This is so that we can get in contact with you.">-->
    <!--            <b-form-input type="tel" v-model="contactForm.tel"></b-form-input>-->
    <!--          </b-form-group>-->

    <!--          <b-form-group label="Message">-->
    <!--            <b-form-textarea rows="10" v-model="contactForm.message"></b-form-textarea>-->
    <!--          </b-form-group>-->

    <!--          <b-form-group>-->
    <!--            <b-overlay :show="sendingContact">-->
    <!--              <b-btn :disabled="sendingContact" variant="primary" type="submit">Send message</b-btn>-->
    <!--            </b-overlay>-->

    <!--          </b-form-group>-->
    <!--        </b-form>-->
    <!--      </b-container>-->
    <!--    </section>-->
    <the-footer-bar></the-footer-bar>
  </div>
</template>

<script>
import TheTopBar from '@/components/Partials/TheTopBar'
import TheFooterBar from '../Partials/TheFooterBar'

export default {
  name: 'HomePage',
  components: {
    TheTopBar,
    TheFooterBar
  },
  data () {
    return {
      reserveForm: {
        invitees: []
      },
      errors: [],
      contactForm: [],
      alert: false,
      sendingContact: false
    }
  },

  mounted () {
    this.animatedScroll()
  },

  methods: {
    animatedScroll () {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        console.log('test')
        anchor.addEventListener('click', function (e) {
          e.preventDefault()

          document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          })
        })
      })
    }
  }
}
</script>
