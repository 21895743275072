<template>
  <footer class="section footer" id="contact-us">
    <b-container>
      <b-row class="justify-content-between">
        <b-col cols="6">
          <p class="font-weight-bold">Trade Apps &copy; 2023</p>
          <a title="0203 633 1775" href="tel:02036331775"><small>
            <ion-icon name="call"></ion-icon>
            0203 633 1775</small></a>
          <a title="info@tradeapps.co.uk" href="mailto:info@tradeapps.co.uk"><small>
            <ion-icon name="mail"></ion-icon>
            info@tradeapps.co.uk</small></a>
        </b-col>
        <hr/>
        <b-col cols="6" class="d-flex justify-content-end">
          <div class="p-3">
            <p class="text-white m-0"><small>Follow Us</small></p>
            <div class="follow-us">
              <a title="Facebook" target="_blank" class="link link--kale" href="https://www.facebook.com/gascertificateapp/"><img
                src="~@/assets/social/facebook.png" alt="Facebook"/></a>
              <a title="Instagram" target="_blank" class="link link--kale" href="https://www.instagram.com/gascertificateapp/"><img
                src="~@/assets/social/instagram.png" alt="Instagram"/></a>
              <a title="Twitter" target="_blank" class="link link--kale" href="https://twitter.com/gas_certificate"><img
                src="~@/assets/social/twitter.png" alt="Twitter"/></a>
              <a title="Linkedin" target="_blank" class="link link--kale" href="https://www.linkedin.com/company/trade-apps-limited"><img
                src="~@/assets/social/linkedin.png" alt="Linkedin"/></a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'TheFooterBar'
}
</script>

<style lang="scss">
.footer {

  .follow-us {
    a {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    img {
      width: 16px;
    }
  }

  p {
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
    display: block;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
</style>
