import Vue from 'vue'
import VueRouter from 'vue-router'

// Layouts
import HomeLayout from '@/components/layouts/HomeLayout'

// Pages
import Home from '@/components/pages/HomePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/*',
    name: 'Home',
    component: Home,
    meta: {
      layout: HomeLayout
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
