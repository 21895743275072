import Vue from 'vue'
import store from '@/store/index'
import router from '@/router/index'

import {BootstrapVue} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import AOS from 'aos'
import 'aos/dist/aos.css'
import Toasted from 'vue-toasted';

import App from './App.vue'
import './assets/scss/app.scss'

window.axios = require('axios')

Vue.use(BootstrapVue)
Vue.use(Toasted);
Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]

AOS.init({
  duration: 1500
});

Vue.use(Toasted, {
  duration: 3000,
});


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
