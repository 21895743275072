<template>
  <nav class="top-bar">
    <b-container class="py-4">
      <router-link title="Home" :to="{ name: 'Home' }"><img class="mb-4" width="120px" src="~@/assets/logo-2022.png" alt="Logo"></router-link>
      <div class="top-bar__menu">
        <a title="Our Apps" class="top-bar__item link link--kale text-white" href="#apps">Our Apps</a>
        <a title="Support" target="_blank" class="top-bar__item link link--kale text-white" href="https://support.tradeapps.co.uk">Support</a>
      </div>
    </b-container>
  </nav>
</template>

<script>
export default {
  name: 'TheTopBar'
}
</script>

<style lang="scss">
.top-bar {
  text-align: center;

  &__menu {
    display: inline-flex;
  }

  &__item {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0 10px;
    border-right: 1px solid white;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      border-right: 0;
      padding-left: 10px;
    }

  }
}

@media only screen and (min-width: 576px) {
  .top-bar {
    text-align: left;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      margin-bottom: 0px !important;
    }
  }
}
</style>
