<template>
  <div class="home-layout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'HomeLayout'
}
</script>
